import axios from 'axios'

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    activeUser: {},
    orderType: null,
    orderDetails: null,
    menuSearchQuery: '',

  },
  getters: {

  },
  mutations: {
    UPDATE_USER_INFO(state, val) {
      state.AppActiveUser = val
    },
    UPDATE_MENU_SEARCH(state, val) {
      state.menuSearchQuery = val
    },
  },
  actions: {
    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },
    updateMenuSearch({ commit }, payload) {
      commit('UPDATE_MENU_SEARCH', payload)
    },
    getBoiToken() {
      return new Promise((resolve, reject) => {
        axios
          .get('')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },
}

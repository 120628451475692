/* eslint-disable no-bitwise */
import axios from '../../libs/axios'

export default {
  namespaced: true,
  state: {
    cartItems: JSON.parse(localStorage.getItem('cart')) || [],
    orderType: localStorage.getItem('orderType') || 'Collection',
    paymentMethod: 'cash',
    discountAmt: 0,
  },
  getters: {},
  mutations: {
    ADD_TO_CART(state, cartItem) {
      const product = cartItem
      const modifers = []
      const UUID = () => {
        // eslint-disable-next-line no-bitwise
        // eslint-disable-next-line no-mixed-operators
        const UID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16))
        return UID
      }
      product.uuid = UUID()

      if (product.menu_modifiers) {
        modifers.value = product.menu_modifiers.filter(
          el => el.selected_modifiers.length > 0,
        )
      }
      const record = state.cartItems.find(item => item.id === product.id)
      if (!record || modifers.value.length > 0) {
        state.cartItems.push(product)
      } else {
        record.qty += 1
      }

      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
    UPDATE_CART(state, product) {
      state.cartItems[2] = product
      localStorage.setItem('cart', JSON.stringify(state.cartItems))
    },
  },
  actions: {
    fetchItems() {
      return new Promise((resolve, reject) => {
        axios
          .get('/product/online_items')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },

    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/product/categories')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    addToCart({ commit }, payload) {
      commit('ADD_TO_CART', payload)
    },
    updateCart({ commit }, payload) {
      commit('UPDATE_CART', payload)
    },

    placeOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/store/place_order', data, { withCredentials: true })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    getOrders() {
      return new Promise((resolve, reject) => {
        axios
          .get('/store/orders', { withCredentials: true })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    createPaymentIntent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/store/payment_intents', data, { withCredentials: true })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
    rateOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/store/update_order', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },

    applyCoupon(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/online_promotions/apply_coupon', data)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error))
      })
    },
  },

}

/* eslint-disable import/no-extraneous-dependencies */
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import * as VeeValidate from 'vee-validate'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import moment from 'moment'
import App from './App.vue'
import router from './router'
import store from './store'
import FeatherIcon from './core/components/feather-icon/FeatherIcon.vue'

import vuetify from './plugins/vuetify'

// import 'leaflet/dist/leaflet.css'

Vue.prototype.moment = moment

Vue.use(VueTelInputVuetify, {
  vuetify,
})
Vue.use(VeeValidate)

// Custom css
require('@/assets/css/custom.css')

// Common scss
require('@/assets/scss/common.scss')

Vue.use(VueCompositionAPI)

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.config.productionTip = false
Vue.prototype.window = window

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

export default {
  namespaced: true,
  state: {
    outletInfo: {},

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-wrapper" },
    [
      _c(
        "v-app",
        { staticClass: "container pos-rel" },
        [_c("v-main", [_c("router-view")], 1)],
        1
      ),
      _c("v-footer", { attrs: { absolute: "" } }, [
        _c("div", { staticClass: "container" }, [
          _c("h4", { staticClass: "ft-main-hd" }, [
            _vm._v(
              " By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies. "
            )
          ]),
          _c("div", { staticClass: "ft-copyright" }, [
            _vm._v(
              " 2022 © CBE Innova. All rights reserved. All trademarks are properties of their respective owners. "
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
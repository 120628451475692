<template>
  <div class="template-wrapper">
    <v-app class="container pos-rel">

      <v-main>
        <!-- <nav-bar /> -->
        <router-view />

      </v-main>
    <!-- <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer />

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar> -->

    </v-app>
    <v-footer
      absolute
    >
      <div class="container">
        <h4 class="ft-main-hd">
          By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies.
        </h4>
        <div class="ft-copyright">
          2022 &copy; CBE Innova. All rights reserved. All trademarks are properties of their respective owners.
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script>
// import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    // NavBar,
  },

  data: () => ({
    //
  }),
}
</script>
<style>
#app {
 font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}

:root{
    --primary-color: #242342!important;
    --secondary-color: #1F7DBB!important;
    --tertiary-color: #FFFFFF!important;
    --success-color: #80b855!important;
    --warning-color: #eaca44!important;
    --error-color: #ef4d4d!important;
  }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'

Vue.use(VueRouter)

// const getPatient = id => {
//   const patient = store.state['app-patient'].patients.find(element => element.id === id)
//   const firstname = patient.first_name
//   return firstname
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // This is the home route which shows the dashboard
    // {
    //   path: '/:id?',
    //   name: 'order',
    //   component: () => import('@/views/Order.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   },

    // },

    {
      path: '/:name/store/:id',
      name: 'store',
      component: () => import('@/views/Store.vue'),
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: 'home/:table_no?',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'home-page',
          name: 'homepage',
          component: () => import('@/components/HomePage.vue'),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: 'cart/:table_no?',
          name: 'cart',
          component: () => import('@/views/Cart.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'payment-sucess',
          name: 'payment-sucess',
          component: () => import('@/views/PaymentSuccess.vue'),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: 'order',
          name: 'order',
          component: () => import('@/views/Orders.vue'),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: 'closed',
          name: 'closed',
          component: () => import('@/views/Close.vue'),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },

    // Redirect to 404 page, if no match found

    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/404.vue'),
      meta: {
        requiresAuth: true,
      },
    },
  ],
})

// navigation guard
router.beforeEach((to, from, next) => {
  next()
  // if (store.state.outlet.outletInfo.is_online) {
  //   next()
  // } else {
  //   next({ path: '/404' })
  // }
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import user from './user'
import cart from './cart'
import outlet from './outlet'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    cart,
    outlet,
  },
  strict: process.env.DEV,
})
